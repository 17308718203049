@use 'common'

.App
	//

.LeadContent
	margin-top: calc(-1 * (var(--content-top-spacing) - 3.75rem))

.Image
	aspect-ratio: calc(var(--App-headerImage-width) / var(--App-headerImage-height))
	width: 3.75rem
	// width: calc(1px * var(--App-headerImage-width)) // The original size of image
	position: relative
	margin-inline: auto
	margin-bottom: 1.875rem
