@use 'common'
@use 'sass:math'

$gap: 1.875rem

.Wrapper
	//

.AsideContent
	display: flex
	gap: $gap
	flex-direction: column
	@media (min-width: common.$break48)
		flex-direction: row
		align-items: center

.Box
	display: flex
	flex-direction: column
	align-items: center
	z-index: 0
	gap: $gap

	@media (min-width: common.$break48)
		width: 50%

	&In
		width: 100%
		display: flex
		justify-content: center
		align-items: center

		&.is_image
			$aspectRatio: calc(var(--AsideContent-image-width) / var(--AsideContent-image-height))
			.Image
				aspect-ratio: $aspectRatio
				width: calc(100% * $aspectRatio)
				position: relative
				overflow: hidden
				border-radius: .625rem

				@support not (aspect-ratio: 1)
					&::before
						content: ''
						display: block
						width: 100%
						padding-top: calc(100% / #{$aspectRatio})

		&.is_video
			.video
				width: 100%

.Content
	max-width: 25.625rem

.isText_right
	@media (min-width: common.$break48)
		flex-direction: row-reverse

.Title
	+common.heading2
	margin-bottom: 2rem

	em
		font-style: normal
		color: green

.Text
	+common.text

.List
	//

.ListItem
	display: flex
	align-items: center

.Dot
	width: .625rem
	height: .625rem

.ListText
	margin: 0.5rem

.PrimaryButton
	margin-top: 1.875rem
	margin-bottom: .9375rem

.Form
	width: 100%
	max-width: calc(33.75rem)
	margin-inline: auto

	@media (min-width: 600px)
		margin-right: 0
