@use 'common'

.Button
	display: inline-block

.Link, .NonLink
	+common.leadParagraph(true)

	&:hover, &:focus-visible
		.TextIcon
			svg
				transform: translateX(.3125rem)

.ButtonSize
	font-weight: 700
	font-size: 1.25rem
	line-height: 1.5rem

.ButtonColor_blue
	color: common.$blue

.ButtonColor_white
	color: common.$light

.Text
	font-weight: 700
	margin: 0

	&Icon
		display: inline-flex
		align-items: center
		gap: .25rem
		font-size: inherit

		svg
			margin-top: -0.25rem
			font-size: .7em
			transition: transform .2s

.BlockButton
	display: flex

	&.Center
		justify-content: center

	&.Right
		justify-content: flex-end
