@use 'common'

.wrapper
	margin-bottom: calc(var(--content-gap-small) - #{common.$contentTopSpacing})

	&.is_topSpacing
		margin-top: var(--content-top-spacing)

.title
	+common.heading
	max-width: 64rem
	margin-inline: auto
	margin-bottom: 1.875rem
	text-align: center

.leadParagraph
	+common.leadParagraph
	max-width: 53.125rem
	margin-inline: auto
	text-align: center
