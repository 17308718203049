@use 'common'

.Background
	background-color: #EDEAE5
	padding: 2rem 0 0

.SpecialLinks
	display: flex
	justify-content: flex-end
	align-items: center
	flex-wrap: wrap
	@media not all and (min-width: common.$break48)
		margin-bottom: 2rem

.DeveloperButton
	padding: 7px 17px
	background: common.$light
	border-radius: 30px
	margin-right: 1rem
	@media (min-width: common.$break48)
		margin-right: 50px

.Logo
	width: 5.625rem

.FooterColumns
	display: flex
	justify-content: space-between
	@media not all and (min-width: common.$break48)
		flex-direction: column

.ContactWrapper
	margin-top: 1.5625rem
	margin-right: 1rem

.Contact
	margin-bottom: 0

.Email
	color: #979797
	font-weight: 700
	font-size: 12px
	line-height: 18.96px

.Copyright,
.Address
	font-weight: 500
	font-size: 14px
	line-height: 18px
	color: #979797
	margin: 0

.Copyright
	margin-top: 1.25rem

.LinkColumns
	display: flex
	flex-wrap: wrap
	@media not all and (min-width: common.$break48)
		margin-top: 2rem

.LinkColumn
	&:nth-child(1)
		margin-right: 50px

.Link
	display: flex
	margin: 20px 0
	cursor: pointer
	&:hover
		color: common.$blue
		// opacity: 0.5
		.LinkDescription
			opacity: 0.5
			color: common.$blue
.Icon
	display: flex
	align-items: center

.LinkTitleWrapper
	margin-left: .8125rem

.LinkTitle,
.LinkDescription
	margin: 0
	font-weight: 700

.LinkTitle
	font-size: 16px
	line-height: 25px

.LinkDescription
	font-size: 12px
	line-height: 19px
	color: #979797

.MadeBy
	font-weight: 500
	font-size: 14px
	line-height: 18px
	color: #979797
	border-top: 1px solid
	padding: 1.25rem 0 4.25rem
	margin-top: 4.25rem
	a
		text-decoration: underline
