@use '../vars'

=inputIsError
	border-color: vars.$dangerRed

=inputErrorMessage
	color: vars.$dangerRed
	line-height: 1.1

=input
	width: 100%
	background-color: #f1f4f7
	color: #0a2540
	font-size: .9375em
	border-radius: .25em
	padding: .625rem .75rem
	border: none
	outline: unset
	font-family: vars.$cooperSansStack

	&:focus-visible
		//

	&::placeholder
		color: #acb9c5
