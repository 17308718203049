@use 'common'
@use 'sass:math'

.AppList
	display: flex
	column-gap: 2.5rem

	@media not all and (min-width: common.$break48)
		flex-direction: column

.Column
	display: flex
	flex-direction: column
	column-count: 2
	@media (min-width: common.$break48)
		width: 50%
		&:nth-last-child(1)
			margin-top: 8.125rem

.App
	display: flex
	flex-direction: column
	margin-bottom: var(--box-spacing)
	background: common.$grey
	border-radius: 1.875rem
	overflow: hidden
	cursor: pointer

.TitleBox
	display: flex
	justify-content: space-between
	gap: 1.25rem
	padding: var(--box-padding) var(--box-padding) 0
	margin: 0 0 1rem

.Title
	+common.leadParagraph
	margin: 0

.TileText
	+common.textSmall
	max-width: 73%
	padding: 0 0 4.25rem var(--box-padding)
	color: #979797
	margin: 0

.TileImage
	display: flex
	max-width: calc((var(--AppList-tileImage-width) / 2) * 1px)
	margin: auto
	padding: 0 1rem

.TileVideo
	display: flex
	width: 100%
	border-radius: 1.875rem

.isBlue
	background: common.$blue
	color: common.$light
	.TileText
		color: common.$offWhite300
