@use 'common'

.wrapper
	width: 0
	min-width: 100%
	overflow: auto

.table
	width: 100%
	border-bottom: 1px solid common.$light2
	border-collapse: collapse
	font-size: .875rem

	&Name
		margin: unset

	&Corner
		display: flex
		align-items: center
		gap: .625rem

	&Title
		//

	&Image
		$aspectRatio: calc(var(--PricingTable-image-width) / var(--PricingTable-image-height))
		aspect-ratio: $aspectRatio
		width: calc(var(--PricingTable-image-width) * 1px)
		position: relative

.header
	border-bottom: 1px solid common.$light2

	&Cell
		text-align: start
		height: fit-content
		padding: 1rem

		&.is_inCorner
			padding-left: 0

		&:not(:last-child)
			border-right: 1px solid common.$light2

.body
	//
