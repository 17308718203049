@use 'common'

.wrapper
	margin: 1rem 0
	a
		font-weight: 600
		color: green

	h1,
	h2,
	h3,
	h4
		+common.heading
		max-width: 53.75rem
		font-weight: 400

	h2
		+common.heading2

	h4
		max-width: 53rem

	ul,
	ol
		max-width: 53rem

	ol
		width: fit-content
		counter-reset: item
		list-style-type: none
		padding: 0
		li
			width: fit-content
			padding: 0.75rem 0
			text-align: start

			&::before
				content: counter(item) " — "
				counter-increment: item

	ol[data-contember-align="center"]
		margin: auto
		li
			//

	p
		+common.text
		max-width: 53rem
		min-height: 1rem

		&[data-contember-align="end"]
			margin-left: auto
			text-align: start
			@media (min-width: common.$break48)
				max-width: 48%
				padding-right: 2.5rem
			@media (min-width: common.$break62)
				padding-right: 5rem
			@media (min-width: common.$break75)
				padding-right: 10rem

		@media not all and (min-width: common.$break48)
			br
				display: none

	[data-contember-align="center"]
		text-align: center
		margin: auto

	[data-contember-align="end"]
		margin-left: auto
		text-align: end
