@use 'common'
@use 'sass:math'

.Gallery
	display: flex
	flex-wrap: wrap

	&.isMultipleColumns
		.Item
			margin: 1rem

	@media (min-width: common.$break48)
		--Container-horizontal-spacing: var(--global-horizontal-spacing)

		max-width: calc(var(--Container-width) + 2 * var(--Container-horizontal-spacing))
		width: 100%
	@media not all and (min-width: common.$break48)
		overflow-x: auto
		scrollbar-width: none
		scrollbar-height: none
		flex-wrap: nowrap
		scroll-snap-type: x mandatory

		-ms-overflow-style: none /* Internet Explorer 10+ */
		scrollbar-width: none /* Firefox */

		&::-webkit-scrollbar
			display: none /* Safari and Chrome */

.ItemWrapper
	width: 100%

@media (min-width: common.$break48)
	.ItemWrapper
		flex-grow: 1
		flex-basis: 50%

	.Gallery
		div:nth-child(2n + 3) + .LastPlaceholder
			display: block
			flex-grow: 1
			flex-basis: 50%

@media (min-width: common.$break62)
	.ItemWrapper
		flex-grow: 1
		flex-basis: math.div(100%, 3)

	.Gallery
		div:nth-child(3n + 4) + .LastPlaceholder
			display: block
			flex-grow: 1
			flex-basis: 2 * math.div(100%, 3)

		div:nth-child(3n + 5) + .LastPlaceholder
			display: block
			flex-grow: 1
			flex-basis: 1 * math.div(100%, 3)

@media (min-width: common.$break75)
	.ItemWrapper
		flex-grow: 1
		flex-basis: 25%

	.Gallery
		div:nth-child(4n + 5) + .LastPlaceholder
			display: block
			flex-grow: 1
			flex-basis: 75%

		div:nth-child(4n + 6) + .LastPlaceholder
			display: block
			flex-grow: 1
			flex-basis: 50%

		div:nth-child(4n + 7) + .LastPlaceholder
			display: block
			flex-grow: 1
			flex-basis: 25%

@media not all and (min-width: common.$break48)
	.ItemWrapper
		flex-shrink: 0
		scroll-snap-align: center

	.ItemWrapper:first-of-type:not(:last-of-type),
	.ItemWrapper:not(:first-of-type)
		max-width: 80%

.Item
	display: flex

.Button
	// cursor: pointer
	// position: absolute
	// inset: 0
	transition-duration: 0.5s
	border: none
	background-color: transparent
	&:hover
		transform: scale(1.03)

.ImageWrapper
	position: relative

.CarouselWrapper
	display: flex
	align-items: center

.Play
	display: flex
	justify-content: center
	align-items: center
	width: 3.125rem
	height: 3.125rem
	padding-left: .3125rem
	position: absolute
	z-index: 2
	left: 50%
	top: 50%
	font-size: 2rem
	transform: translate(-50%, -50%)
	pointer-events: none
	background-color: common.$dark
	cursor: pointer
	border-radius: 50%
	z-index: 0

.Image
	display: flex
	position: relative
	width: 100%
	border-radius: .625rem
	overflow: hidden

.Video
	aspect-ratio: var(--Gallery-video-width) / var(--Gallery-video-height)
	width: 100%

	&In
		width: 100%

.Arrow
	display: none
	margin: 0 0.5rem

.Back
	transform: rotateZ(180deg)

.isVisible
	display: flex
	@media (min-width: common.$break30)
		display: none

.Alt
	color: #BDBDBD
	font-weight: 500
	font-size: 14px
	margin-top: 0.25rem
	@media not all and (min-width: common.$break48)
		text-align: center
