@use 'sass:math'

// Normalizes height to 1em while preserving aspect ratio
=normalizeSize($intrinsicWidth, $intrinsicHeight)
	$height: 1em

	width: math.div($intrinsicWidth, $intrinsicHeight) * $height
	height: $height

.icon
	// Add all svg icons and corresponding dimensions

	&-arrow
		+normalizeSize(30, 30)
	&-cross
		+normalizeSize(30, 30)
	&-go
		+normalizeSize(512, 512)
	&-hamburger
		+normalizeSize(30, 30)
	&-play
		+normalizeSize(9, 14)
	&-checkmark
		+normalizeSize(24, 24)
		&WithCircle
			+normalizeSize(24, 24)
