@use 'common'
@use 'sass:color'
@use 'sass:math'

$linkBlockSpacing: .625rem
$submenuGap: 1.25rem
$submenuTopOffset: calc(100% - #{$linkBlockSpacing})
$headerMarginTopSpacing: var(--global-horizontal-spacing)
$headerMobileMargin: calc(var(--Container-horizontal-spacing) / 2)
$submenuPaddingInline: calc(var(--Container-horizontal-spacing) - $headerMobileMargin)

=buttonStyles
	background-color: common.$grey
	transition: background-color .2s

	&:hover, &:focus-visible
		background-color: color.scale(common.$grey, $lightness: -5%)

=hamburgerLayer
	width: 100%
	height: 2px
	background-color: #000000
	z-index: 0

=dashedDivider
	position: relative

	&::before
		content: ""
		position: absolute
		bottom: 0
		left: 0
		right: 0
		height: 1px
		background: linear-gradient(90deg, rgba(common.$blue ,0.3), rgba(common.$blue ,0.3) 50%, transparent 0, transparent)
		background-size: .5rem .0625rem

.Wrapper
	//

.Main
	display: flex
	justify-content: space-between
	align-items: center
	margin-top: $headerMarginTopSpacing

.Logo
	position: relative
	z-index: 2

	&Link
		display: flex

	&Mobile
		position: absolute
		left: $submenuPaddingInline
		top: calc($headerMarginTopSpacing - $headerMobileMargin)
		z-index: 0

		@media not all and (min-width: common.$break37)
			display: none

.Links
	display: none
	z-index: 2
	flex-direction: column

	@media (min-width: common.$break62)
		display: flex
		flex-direction: row

.Link
	$link: &

	+common.textSmall
	color: common.$blue
	transition: color .2s

	&Title
		//

	&Arrow
		transition: transform .2s

	&:hover
		color: color.scale(common.$blue, $lightness: 35%)

		#{$link}Arrow
			transform: translateX(.125rem)

	@media not all and (min-width: common.$break48)
		margin: 0
		font-size: 1.25rem
		line-height: 1.875rem

	@media not all and (min-width: common.$break62)
		color: common.$dark
		display: flex
		justify-content: space-between
		align-items: center

	@media (min-width: common.$break62)
		padding: $linkBlockSpacing 1.25rem

.LanguageWrapper
	display: flex
	align-items: center

.Locale
	color: common.$dark
	font-size: 1rem
	display: block
	margin-right: 1rem
	text-transform: capitalize

.isActiveLocale
	display: none

.SpecialLink
	+buttonStyles
	display: inline-block
	border-radius: 1.875rem
	padding: 0.5rem 1rem
	font-weight: 700
	font-size: 1rem
	line-height: 1.375

	&.isDesktop
		@media not all and (min-width: common.$break62)
			display: none

.MenuBox
	display: flex
	position: relative
	z-index: 0

	&:hover

		.SubmenuWrapper
			visibility: initial
			opacity: 1

.hasSubmenu
	&::before
		content: ""
		position: absolute
		top: $submenuTopOffset
		width: 1.25rem
		height: 1.25rem
		left: 50%
		background-color: #fff
		transform: translateY(1.5em) translate(-50%,-50%) rotate(45deg)
		z-index: 1
		opacity: 0
	&:hover::before
		opacity: 1

.SubmenuWrapper
	visibility: hidden
	opacity: 0
	position: absolute
	min-width: 15.625rem
	width: 25rem
	top: $submenuTopOffset
	left: 50%
	transform: translateX(-50%)
	transition: all .2s

	&.isMultipleColumns
		width: 37.5rem

		.Submenu
			grid-column-gap: $submenuGap

.LastItem
	right: 0
	left: auto
	transform: translateX(0)

.Submenu
	display: grid
	grid-template-columns: 1fr 1fr
	padding: 2rem 1.5rem
	border-radius: .625rem
	background-color: #fff
	box-shadow: 0rem 0.25rem 1.5rem 0.5rem rgb(0 0 0 / 15%)
	margin-top: 1.5rem

.SubmenuSection
	display: contents

.SubmenuGroupTitle
	text-transform: uppercase
	color: #727f96
	margin: unset
	margin-bottom: 0.6em
	font-size: 1rem

	@media not all and (min-width: common.$break62)
		display: none

.SubmenuLink
	width: 100%
	display: flex
	align-items: center
	gap: .75rem
	padding-block: .25rem
	font-size: .8125rem
	transition: color .2s

	@media (min-width: common.$break62)
		padding-block: 0.5rem
		line-height: 1.875rem

	&:hover
		color: common.$blue

.SubmenuIcon
	position: relative
	width: 2rem
	height: 2rem

.SubmenuTexts
	//

.SubmenuTitle
	line-height: 1.1
	font-size: .875rem
	margin: 0
	margin-bottom: .375rem

.SubmenuText
	font-size: 0.8rem
	opacity: 0.5
	margin: 0
	line-height: 1

.isActiveLink
	color: common.$blue
	@media not all and (min-width: common.$break62)
		text-decoration: underline

.HeaderMobile
	@media (min-width: common.$break62)
		display: none

.IconHamburger
	$iconHamburger: &

	display: flex
	border: none
	cursor: pointer

	&.is_hamburger
		$hamburgerGap: 4px

		+buttonStyles
		flex-direction: column
		justify-content: center
		width: 3rem
		height: 2rem
		padding: 0 1rem
		border-radius: 1rem

		#{$iconHamburger}
			&Icon
				position: relative
				+hamburgerLayer

				&::before, &::after
					+hamburgerLayer
					content: ''
					position: absolute
					left: 0
					display: block

				&::before
					top: -$hamburgerGap

				&::after
					top: $hamburgerGap

.Close
	position: absolute
	top: $submenuPaddingInline
	right: $submenuPaddingInline
	padding: 1.25rem
	z-index: 2
	opacity: 0
	visibility: hidden
	background-color: transparent
	transition-property: visibility, opacity
	transition-duration: .2s

.IconHamburger, .Close
	@media (min-width: common.$break62)
		display: none

.MobileHeader
	visibility: hidden
	opacity: 0
	flex-direction: column
	position: absolute
	inset: 0
	bottom: auto
	margin: calc(var(--Container-horizontal-spacing) / 2)
	padding-block: (common.$mobileHeaderHeight + 1rem) 1.25rem
	padding-inline: $submenuPaddingInline
	z-index: 1
	background-color: #ffffff
	box-shadow: 0 3.125rem 6.25rem -1.25rem rgba(50,50,93,0.25),0 1.875rem 3.75rem -1.875rem rgba(0,0,0,0.3)
	border-radius: .5rem
	transition-property: visibility, opacity
	transition-duration: .2s

	@media (min-width: common.$break37) and (max-width: calc(common.$break62 - 1px))
		width: 50%
		max-width: 25rem
		margin-left: auto

	@media (min-width: common.$break62)
		display: none

.MobileSections
	//

.MobileSection
	//

.MobileSectionIn
	+dashedDivider
	padding-bottom: 1.25rem

.MobileColumn
	//

.MobileLinks
	display: block
	margin-top: 2rem
	color: common.$dark
	text-align: center

.SubmenuItems
	list-style-type: none
	padding: unset
	grid-row: 2
	margin: unset

.SubmenuItem
	margin-top: .625rem

.MobileLink
	+dashedDivider
	padding-block: $linkBlockSpacing

.isActive
	visibility: initial
	opacity: 1

	.Link
		display: flex
