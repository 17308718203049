@use 'common'

.row
	&:not(:last-child)
		&:not(.is_header)
			border-bottom: 1px solid common.$light2

	&.is_header
		.cell
			padding-block: 1rem .5rem
		&:not(.isFirstGroup)
			.cell
				padding-top: 3rem

.cell
	&:not(:last-child)
		border-right: 1px solid common.$light2
