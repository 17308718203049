@use 'common'
@use 'sass:math'

$leftOffset: 13%

.CustomerJumbotron
	position: relative

.TitleWrapper
	position: relative
	z-index: 0

.Title
	position: relative
	z-index: 1
	font-family: common.$cooperSansStack
	margin-block: 0
	+common.heading1

	&Line
		display: inline

		&.FirstLine,
		&.SecondLine,
		&.ThirdLine
			@media not all and (min-width: common.$break37)
				&::before
					content: ' '

		@media (min-width: common.$break37)
			display: flex
			&.FirstLine
				justify-content: flex-end
				padding-right: 16%

			&.SecondLine
				justify-content: flex-end

			&.ThirdLine
				justify-content: flex-start
				padding-left: $leftOffset

	@media (min-width: common.$break48)
		font-size: 5.625rem
		line-height: 1.5
	@media (min-width: common.$break62)
		font-size: 7.5rem
		line-height: 1.20833
	@media (min-width: common.$break75)
		font-size: 8.75rem
		line-height: 1.1285

.Media
	display: none

	@media (min-width: common.$break37)
		display: flex
		flex-direction: column
		pointer-events: none
		position: absolute
		inset: 0

.MediaItem
	--CustomerJumbotron-mediaItem-divider: 3
	--CustomerJumbotron-mediaItem-maxWidth: calc((var(--CustomerJumbotron-mediaItem-width) / var(--CustomerJumbotron-mediaItem-divider)) * 1px)

	@media (min-width: common.$break37)
		max-width: var(--CustomerJumbotron-mediaItem-maxWidth)
		position: absolute

		&:nth-child(1)
			top: 0
			left: 0
		&:nth-child(2)
			top: 20%
			left: 15%
		&:nth-child(3)
			top: 40%
			left: 30%
		&:nth-child(4)
			top: 55%
			left: 0
		&:nth-child(5)
			top: 74%
			right: 15%

		@media (min-width: common.$break48)
			--CustomerJumbotron-mediaItem-divider: 2

			&:nth-child(4)
				top: 60%

			&:nth-child(5)
				right: 0

.Text
	max-width: 43.75rem

	@media (min-width: common.$break37)
		padding-left: $leftOffset

.Link
	margin-top: var(--content-gap-small)

	@media (min-width: common.$break48)
		&.Center
			padding-left: 10%
