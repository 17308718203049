@use 'common'

$-gapBig: 7rem
$-gapMedium: 3.75rem
$-gapSmall: var(--content-gap-small)

.wrapper
	--ContentRenderer-gap-default: 7.5rem
	--ContentRenderer-gap-typography: 1.25rem

	position: relative
	width: 100%
	margin-top: common.$contentTopSpacing
	> *:last-child
		&.section,
		&:not(.section)
			&:not(.is_background_grey)
				margin-bottom: var(--ContentRenderer-gap-default)

	@media (min-width: common.$break48)
		--ContentRenderer-gap-default: 11.25rem
		--ContentRenderer-gap-typography: #{$-gapSmall}

.section

	&.is_background_white
		background-color: common.$light

	&.is_background_grey
		background-color: common.$grey

	&:not(.is_background_white)
		&:not(.is_wysiwyg)
			padding-bottom: $-gapBig

		.section:not(.is_background_grey) + &.is_background_grey
			padding-top: $-gapBig
			padding-bottom: $-gapSmall

	&.is_background_white + &,
	& + &.is_background_white,
	&:not(.is_wysiwyg) + &.is_wysiwyg
		margin-top: var(--ContentRenderer-gap-default)

	$referencesWithSmallGapsAfterButton: ".is_reference_gallery"
	$referencesWithMediumGapsAfterWysiwyg: ".is_reference_button"
	&.section
		&.is_wysiwyg + &:is(#{$referencesWithMediumGapsAfterWysiwyg}),
		&.is_reference_button + &:is(#{$referencesWithSmallGapsAfterButton})
			margin-top: $-gapMedium

	&.section
		&.is_wysiwyg + &:not(.is_wysiwyg),
		&:not(.is_wysiwyg) + &.is_reference_button
			margin-top: $-gapSmall

	$referencesWithTypographyGapsAfterWysiwyg: ".is_reference_boxes, .is_reference_appTiles"
	&.section
		&.is_wysiwyg + &:is(#{$referencesWithTypographyGapsAfterWysiwyg})
			margin-top: var(--ContentRenderer-gap-typography)

.notImplemented
	border: 1px solid
	background-color: rgba(#ff0000, 0.07)
	color: #ff0000
	padding: 1em
	text-align: center

	&_name
		font-size: 2em
		text-transform: capitalize
