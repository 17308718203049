@use 'common'
@use 'sass:color'

$formPadding: 1rem
$successColor: #107857
$successBackgroundColor: #EAF8F3
$errorColor: #B13B3C
$errorBackgroundColor: #FFEAEA

.wrapper
	position: relative
	display: flex
	flex-direction: column
	gap: .9375rem
	padding: $formPadding
	background: #FFFFFF
	border-radius: .5rem

.label
	width: 100%

	@media (min-width: common.$break37)
		display: flex
		justify-content: space-between
		align-items: center
		gap: .9375rem

	&Text
		display: block
		margin-bottom: .25rem

		@media (min-width: common.$break37)
			width: 25%

.input
	width: 100%

	@media (min-width: common.$break37)
		width: 75%

.button
	width: fit-content
	margin-left: auto
	display: inline-block
	border-radius: 1.875rem
	padding: 0.5rem 1rem
	font-weight: 700
	font-size: 1em
	line-height: 1.375
	background-color: common.$blue
	transition: background-color 0.2s
	color: #ffffff
	cursor: pointer
	border: unset
	outline: unset

	&:hover, &:focus-visible
		background-color: color.adjust(common.$blue, $lightness: 5%)

.notification
	position: absolute
	inset: 0
	display: flex
	justify-content: center
	align-items: center
	z-index: 0
	visibility: hidden
	opacity: 0
	transition-property: opacity, visibility
	transition-duration: .2s

	&.is_visible
		opacity: 1
		visibility: initial

	&.is_success &
		&In
			background-color: $successBackgroundColor
			color: $successColor

		&Image
			border: .625rem solid $successColor

			&::before
				content: ""
				position: absolute
				left: .9375rem
				bottom: .5rem
				height: 40%
				width: .625rem
				background-color: $successColor
				transform: rotate(-45deg)

			&::after
				content: ""
				position: absolute
				left: .9375rem
				bottom: 2.1875rem
				height: .625rem
				width: 80%
				background-color: $successColor
				transform: translateY(50%) rotate(-45deg)

	&.is_error &
		&In
			background-color: $errorBackgroundColor
			color: $errorColor

		&Image
			border: .625rem solid $errorColor

			&::before
				content: ''
				position: absolute
				left: 50%
				top: 50%
				width: 100%
				height: 10px
				transform: translate(-50%, -50%) rotate(45deg)
				background-color: $errorColor

	&In
		display: flex
		justify-content: center
		flex-direction: column
		align-items: center
		gap: 1.25rem
		padding: 1.25rem
		border-radius: .25rem

	&Image
		position: relative
		width: 6.25rem
		height: 6.25rem
		aspect-ratio: 1
		border-radius: 50%

	&Message
		//
