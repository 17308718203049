@use 'common'

.wrapper
	//

.main

	&In
		border-radius: 1.875rem
		overflow: hidden

		&.is_background_grey
			background-color: common.$grey

		&.is_background_blue
			background-color: common.$blue
			color: #fff

.content
	padding: 3.75rem var(--box-padding)
	padding-bottom: 0

.title
	+common.heading2
	text-align: center
	max-width: 43.75rem
	margin-inline: auto
	margin-bottom: .4em

.text
	+common.text
	max-width: 45rem
	margin-inline: auto
	text-align: center

	@media (min-width: common.$break30)
		text-align: initial

.gallery
	margin-top: 3.125rem

.button
	margin-top: var(--content-gap-small)
