@use 'common'

.wrapper
	display: flex
	flex-direction: column

.header
	//

.main
	flex-grow: 1

	&.is_topSpacing
		padding-top: var(--content-top-spacing)

.footer
	//
