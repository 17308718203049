@use 'common'
@use 'sass:math'

.BoxTile
	display: flex
	flex-direction: column
	border-radius: 1.875rem
	background-color: common.$grey
	overflow: hidden

.isBackground_blue
	background-color: common.$blue
	.Title
		color: common.$light
	.Text
		color: common.$offWhite300

.isBackground_grey
	background-color: common.$grey

.isAlign_top
	justify-content: flex-start
.isAlign_center
	justify-content: center
	margin: auto 0
.isAlign_bottom
	justify-content: flex-end
	margin-top: auto

.TextBox
	display: flex
	flex-direction: column

.TitleBox
	display: flex
	justify-content: space-between
	align-items: center
	gap: 1.25rem
	padding: var(--box-padding) var(--box-padding) 0
	margin: 0 0 1rem

.Title
	+common.leadParagraph
	margin: 0

.Text
	+common.textSmall
	padding: 0 0 4.25rem var(--box-padding)
	color: #979797
	margin: 0
	max-width: 73%

.MediaItem
	display: flex
	margin: auto

	&.is_image
		max-width: calc((var(--BoxTile-image-width) / 2) * 1px)

	&.is_video
		//

.Video
	display: flex
	width: 100%
