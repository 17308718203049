@use 'common'

.cell
	padding-block: 1rem

	&:not(:last-child)
		border-right: 1px solid common.$light2

	&:not(.is_header)
		text-align: center

	&.is_header
		width: 30%

	&.is_shifted
		padding-left: 1rem
