@use 'common'
@use 'sass:math'

.Boxes
	display: grid
	gap: var(--box-spacing)

	@media (min-width: common.$break48)
		grid-template-columns: var(--Boxes-width-first) var(--Boxes-width-second)

		// calc(100fr - var(--Boxes-width))
